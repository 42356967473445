<template>
  <div class="fullwidth form-field">
    <h3>{{ input.title }}</h3>
    <div
      :id="inputKey"
      class="select-container"
      :class="{hasError: input.hasError }"
    >
      <span
        v-for="(option, index) in input.options"
        :key="index"
        @change="inputUpdate(input, currentVal)"
      >
        <!-- eslint-disable-next-line -->
        <input
          :id="inputKey + '-' + index"
          v-model="currentVal"
          :name="inputKey + '-' + index"
          type="checkbox"
          :value="option"
        />
        <label
          :for="inputKey + '-' + index"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span v-if="input.optionsObjects">
            <span v-html="input.optionsObjects[option]" />
          </span>
          <span v-else>
            <span v-html="option" />
          </span>
          <!-- eslint-enable -->
        </label>
      </span>
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang].required_error }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{ input.errorMsg }}</label>
  </div>
</template>

<script>

export default {
    name: "FormBlockSelect",
    props: {
        formData: {
            type: Object,
            default: new Object()
        },
        activeLang: {
            type: String,
            default: "English"
        },
        input: {
            type: Object,
            required: true
        },
        inputKey: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            currentVal: this.formData[this.inputKey] || [],
            selectedTitle: "",
        };
    },
    created() {
        let targetTitle = this.formData[this.input.index] || this.input.title;
        //Update select title to the current language selection.
        this.selectedTitle = this.lang[this.activeLang][targetTitle];
    },
    methods: {
        inputUpdate: function (input, newVal) {
            // Send the new value up to the parent.
            this.$emit('formUpdate', newVal)
            // If there's no error, don't perform a valid check to see if it has updated.
            if (!input.hasError)
                return;
            // If it had a valid error we'll validate one more time.
            if (input.errorMsg &&
                input.isValid) {
                input.errorMsg = null;
            }
            // If there's no validation or required error we remove the hasError property.
            if (!input.errorMsg || input.requiredError) {
                input.hasError = false;
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 0 10px;
  position: absolute;
  background-color: white;
  padding: 0 5px;
}

.select-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  border: 2px solid black;
  padding: 20px 20px 10px;
  margin-top: 7px;
}
.select-container>span {
  flex-basis: 30%;
  min-height: 75px;
  text-align: left;
  display: flex;
  align-items: center;
}

.select-container input[type=checkbox] {
  float: left;
  margin: 0 10px 0 0;
  transform: scale(1.2);
}
</style>