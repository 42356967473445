<template>
  <div class="container-main">
    <div
      v-if="debugging"
      class="debugging-container"
    >
      <pre v-if="JSONData">
      <code>
        {{ JSONData }}
      </code>
      </pre>
      <h1 style="color: rgb(214, 88, 88)">
        DEBUGGING MODE ENABLED.
      </h1>
      <div
        class="debug-button"
        @click="clearFormData"
      >
        <label class="submit-button-text"> CLEAR LOCAL DATA </label>
      </div>
      <div
        class="debug-button"
        @click="submitForm"
      >
        <label class="submit-button-text"> TEST SUBMIT </label>
      </div>
    </div>
    <FormCompleteHeader
      v-if="submitted === true"
      id="FormCompleteHeader"
      :active-lang="activeLang"
    />
    <FormFileHeader
      id="FormFileHeader"
      :active-lang="activeLang"
    />
    <label
      v-if="formError === true"
      id="error-notice"
    >
      {{ lang[activeLang].review_error }}</label>
    <Loading v-if="loading" />
    <div v-if="submitted != true && !loading">
      <slot v-for="(formSection, index) in formStructure">
        <form-section
          v-if="
            typeof formSection.shouldShow === 'undefined' ||
              (typeof formSection.shouldShow != 'undefined' &&
                formSection.shouldShow() === true)
          "
          :id="index"
          :key="index"
          :title="index"
          :form-section="formSection"
          :active-lang="activeLang"
          :form-data="formData"
          @formUpdate="formFieldChanged"
        />
      </slot>
      <div
        v-if="isBaseInfoComplete()"
        class="submit-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          {{ lang[activeLang].submit }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from './FormSection.vue'
import FormCompleteHeader from './FormCompleteHeader.vue'
import FormFileHeader from './FormFileHeader.vue'
import Loading from './Loading.vue'
import formRequest from "../api.js"

function isValidEmail(email) {
  // Regular expression taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  if (!re.test(email)) {
    return false
  }
  return true
}

function isValidPhoneNumber(number) {
  const re = /^(\+\d+)?\s*\(?\d+\)?[\s.-]?\d*[\s.-]?\d*$/
  if (!re.test(number)) {
    return false
  }
  return true
}

function isValidZipCode(zipCode) {
  const re = /^\d{5}(?:[-\s]\d{4})?$/
  if (!re.test(zipCode)) {
    return false
  }
  return true
}

export default {
  name: 'InquiryForm',
  components: {
    FormSection,
    FormFileHeader,
    FormCompleteHeader,
    Loading
  },
  props: {
    activeLang: {
      type: String,
      default: localStorage.activeLang || 'English',
    },
  },
  data() {
    return {
      formData: JSON.parse(localStorage.formData || '{}'),
      erroredFields: [],
      JSONData: null,
      submitted: false,
      formError: false,
      debugging: false,
      loading: true,
      formStructure: {},
    }
  },
  computed: {},
  watch:{
    activeLang() {
      location.reload();
    }
  },
  async beforeMount() {
    this.loading = true
    try {
      await formRequest.requestFormData()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  mounted() {
    // Build form inputs here.

    /* 
      Preliminary Questions
    */
    this.addSection('pre_questions')

    this.addToRow('pre_questions', 0, 'filer_country', {
      title: this.lang[this.activeLang].pre_questions.location.title,
      type: 'select',
      options: formRequest.apiData.get_country_list.options,
      required: true,
      fullwidth: true,
    })

    this.addToRow('pre_questions', 1, 'filer_city', {
      title: this.lang[this.activeLang].pre_questions.location.filer_city,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('filer_country')
      }
    })

    this.addToRow('pre_questions', 1, 'filer_state_typed', {
      title: this.lang[this.activeLang].pre_questions.location.filer_state,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('filer_country') && this.getFormData('filer_country') != formRequest.apiData.get_country_list.options[0]
      }
    })

    this.addToRow('pre_questions', 1, 'filer_state', {
      title: this.lang[this.activeLang].pre_questions.location.filer_state,
      type: 'select',
      options: formRequest.apiData.get_state_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('filer_country') && this.getFormData('filer_country') == formRequest.apiData.get_country_list.options[0]
      }
    })

    this.addToRow('pre_questions', 2, 'incident_city', {
      title: this.lang[this.activeLang].pre_questions.location.affected_city,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('filer_country')
      }
    })

    this.addToRow('pre_questions', 2, 'incident_state_typed', {
      title: this.lang[this.activeLang].pre_questions.location.affected_state,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('filer_country') && this.getFormData('filer_country') != formRequest.apiData.get_country_list.options[0]
      }
    })

    this.addToRow('pre_questions', 2, 'incident_state', {
      title: this.lang[this.activeLang].pre_questions.location.affected_state,
      type: 'select',
      options: formRequest.apiData.get_state_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('filer_country') && this.getFormData('filer_country') == formRequest.apiData.get_country_list.options[0]
      }
    })

    this.addToRow('pre_questions', 3, 'nature', {
      title: this.lang[this.activeLang].pre_questions.nature_select.title,
      type: 'select',
      options: formRequest.apiData.get_nature_list.options,
      required: true,
      fullwidth: true,
    })

    /* 
      CONTACT INFORMATION
    */
    this.addSection('contact_info', {
      shouldShow: () => {
        return this.isBaseInfoComplete();
      },
    })

    this.addToRow('contact_info', 0, 'filer_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      required: true
    })

    this.addToRow('contact_info', 0, 'filer_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      required: true
    })

    this.addToRow('contact_info', 1, 'filer_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: true,
    })

    this.addToRow('contact_info', 1, 'filer_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
    })

    this.addToRow('contact_info', 2, 'filer_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      required: true,
      thirdwidth: true,
    })

    this.addToRow('contact_info', 2, 'filer_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_state_list.options,
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_country') == formRequest.apiData.get_country_list.options[0]
      },
    })

    this.addToRow('contact_info', 2, 'filer_state_typed', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_country') != formRequest.apiData.get_country_list.options[0]
      }
    })

    this.addToRow('contact_info', 2, 'filer_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      required: true,
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
    })

    this.addToRow('contact_info', 3, 'filer_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      required: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
    })

    this.addToRow('contact_info', 3, 'filer_phone2', {
      title: this.lang[this.activeLang].contact_info.phone2,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
    })

    this.addToRow('contact_info', 4, 'filer_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      fullwidth: true,
      required: true,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error
        }
      },
    })

    this.addToRow('contact_info', 5, 'filer_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_gender_list.options,
      required: true,
      shouldShow: () => {
        return formRequest.apiData.get_gender_list.options[0]
      },
    })

    this.addToRow('contact_info', 5, 'filer_dob', {
      title: this.lang[this.activeLang].contact_info.dob,
      type: 'datepicker'
    })

    this.addToRow('contact_info', 5, 'filer_gender_typed', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.other_title,
      type: 'text',
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_pronoun') == 'Other'
      },
    })

    this.addToRow('contact_info', 6, 'filer_religion', {
      title: this.lang[this.activeLang].contact_info.religion_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_religion_list.options,
      required: true,
      shouldShow: () => {
        return formRequest.apiData.get_religion_list.options[0]
      },
    })

    this.addToRow('contact_info', 7, 'filer_religion_typed', {
      title: this.lang[this.activeLang].contact_info.religion_select.other_title,
      type: 'text',
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_religion') == 'Other'
      }
    })

    this.addToRow('contact_info', 8, 'filer_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_Ethnicity_list.options,
      required: true,
      shouldShow: () => {
        return formRequest.apiData.get_Ethnicity_list.options[0]
      },
    })

    this.addToRow('contact_info', 9, 'filer_ethnicity_typed', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.other_title,
      type: 'text',
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_ethnicity') == 'Other'
      }
    })

    /* 
      CLAIM INFORMATION
    */
    this.addSection('claim_info', {
      shouldShow: () => {
        return this.isBaseInfoComplete();
      },
    })

    this.addToRow('claim_info', 0, 'filer_claim', {
      title: this.lang[this.activeLang].claim_info.claim_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_claim_type_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to report a violation of my rights' && formRequest.apiData.get_claim_type_list.options[0]
      },
    })

    this.addToRow('claim_info', 0, 'filer_claim_typed', {
      title: this.lang[this.activeLang].claim_info.complaint,
      type: 'textarea',
      charlimit: 1000,
      fullwidth: true,
      required: true,
      shouldShow: () => {
        return this.getFormData('nature') == "I would like to report bias in the news media" || this.getFormData('nature') == "I would like to report bias in a publication"
      }
    })

    this.addToRow('claim_info', 0, 'material_request', {
      title: this.lang[this.activeLang].claim_info.material_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_material_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && formRequest.apiData.get_material_list.options[0]
      },
    })

    this.addToRow('claim_info', 0, 'scheduled_event_select', {
      title: this.lang[this.activeLang].claim_info.scheduled_event_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request a speaker for an event'
      }
    })

    this.addToRow('claim_info', 1, 'event_datetime', {
      title: this.lang[this.activeLang].claim_info.scheduled_event_select.date,
      type: 'datepicker',
      timepicker: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('scheduled_event_select') == 'Yes' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      },
    })

    this.addToRow('claim_info', 1, 'event_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('scheduled_event_select') == 'No' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      },
    })

    this.addToRow('claim_info', 1, 'event_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('scheduled_event_select') == 'No' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      },
    })

    this.addToRow('claim_info', 2, 'event_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('scheduled_event_select') == 'No' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      },
    })

    this.addToRow('claim_info', 2, 'event_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_state_list.options,
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_country') == formRequest.apiData.get_country_list.options[0] && this.getFormData('scheduled_event_select') == 'No' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      },
    })

    this.addToRow('claim_info', 2, 'event_state_typed', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('filer_country') != formRequest.apiData.get_country_list.options[0] && this.getFormData('scheduled_event_select') == 'No' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      }
    })

    this.addToRow('claim_info', 2, 'event_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('scheduled_event_select') == 'No' && this.getFormData('nature') == 'I would like to request a speaker for an event'
      },
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
    })

    this.addToRow('claim_info', 1, 'material_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && (this.getFormData('material_request') == 'Request for CAIR Publications' || this.getFormData('material_request') == 'Request for Religious Materials (Non-Incarcerees)')
      },
    })

    this.addToRow('claim_info', 1, 'material_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && (this.getFormData('material_request') == 'Request for CAIR Publications' || this.getFormData('material_request') == 'Request for Religious Materials (Non-Incarcerees)')
      },
    })

    this.addToRow('claim_info', 2, 'material_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && (this.getFormData('material_request') == 'Request for CAIR Publications' || this.getFormData('material_request') == 'Request for Religious Materials (Non-Incarcerees)')
      },
    })

    this.addToRow('claim_info', 2, 'material_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_state_list.options,
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && (this.getFormData('filer_country') == formRequest.apiData.get_country_list.options[0] && (this.getFormData('material_request') == 'Request for CAIR Publications' || this.getFormData('material_request') == 'Request for Religious Materials (Non-Incarcerees)'))
      },
    })

    this.addToRow('claim_info', 2, 'material_state_typed', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && (this.getFormData('filer_country') != formRequest.apiData.get_country_list.options[0] && (this.getFormData('material_request') == 'Request for CAIR Publications' || this.getFormData('material_request') == 'Request for Religious Materials (Non-Incarcerees)'))
      }
    })

    this.addToRow('claim_info', 2, 'material_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && (this.getFormData('material_request') == 'Request for CAIR Publications' || this.getFormData('material_request') == 'Request for Religious Materials (Non-Incarcerees)')
      },
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
    })

    this.addToRow('claim_info', 3, 'publication_request', {
      title: this.lang[this.activeLang].claim_info.material_select.publication_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_publication_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && this.getFormData('material_request') == 'Request for CAIR Publications' && formRequest.apiData.get_publication_list.options[0]
      },
    })

    this.addToRow('claim_info', 4, 'filer_request', {
      title: this.lang[this.activeLang].claim_info.request,
      type: 'textarea',
      charlimit: 1000,
      required: false,
    })

    this.addToRow('claim_info', 5, 'filer_guidebooks', {
      title: this.lang[this.activeLang].claim_info.material_select.publication_select.guidebooks,
      type: 'blockSelect',
      options: formRequest.apiData.get_guidebooks.options,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && this.getFormData('material_request') == 'Request for CAIR Publications' && this.getFormData('publication_request') == "Guides, Handbooks and Toolkits" && formRequest.apiData.get_guidebooks.options[0]
      }
    })

    this.addToRow('claim_info', 5, 'filer_knowyourights', {
      title: this.lang[this.activeLang].claim_info.material_select.publication_select.knowyourights,
      type: 'blockSelect',
      options: formRequest.apiData.get_knowyourights.options,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && this.getFormData('material_request') == 'Request for CAIR Publications' && this.getFormData('publication_request') == "Know Your Rights" && formRequest.apiData.get_knowyourights.options[0]
      }
    })

    this.addToRow('claim_info', 5, 'filer_reportsmaterials', {
      title: this.lang[this.activeLang].claim_info.material_select.publication_select.reportsmaterials,
      type: 'blockSelect',
      options: formRequest.apiData.get_reportsmaterials.options,
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to request CAIR services, publications or materials' && this.getFormData('material_request') == 'Request for CAIR Publications' && this.getFormData('publication_request') == "Reports and Other Publications" && formRequest.apiData.get_reportsmaterials.options[0]
      }
    })

    this.addSection('report_incident', {
      shouldShow: () => {
        return this.getFormData('nature') == 'I would like to report a violation of my rights'
      }
    })

    this.addToRow('report_incident', 0, 'affected_person', {
      title: this.lang[this.activeLang].report_incident.self_select.title,
      type: 'select',
      options: ['Myself', 'Someone else'],
      optionsObjects: this.lang[this.activeLang].report_incident.self_select.options,
      required: true,
      fullwidth: true,
    })

    this.addToRow('report_incident', 1, 'affected_firstname', {
      title: this.lang[this.activeLang].report_incident.affected + this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 1, 'affected_lastname', {
      title: this.lang[this.activeLang].report_incident.affected + this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 2, 'affected_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 2, 'affected_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 3, 'affected_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 3, 'affected_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_state_list.options,
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && this.getFormData('filer_country') == formRequest.apiData.get_country_list.options[0]
      },
    })

    this.addToRow('report_incident', 3, 'affected_state_typed', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'text',
      required: true,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && this.getFormData('filer_country') != formRequest.apiData.get_country_list.options[0]
      }
    })

    this.addToRow('report_incident', 3, 'affected_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      required: true,
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 4, 'affected_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      required: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 4, 'affected_phone2', {
      title: this.lang[this.activeLang].contact_info.phone2,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 5, 'affected_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      fullwidth: true,
      required: true,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 6, 'affected_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_gender_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && formRequest.apiData.get_gender_list.options[0]
      }
    })

    this.addToRow('report_incident', 6, 'affected_dob', {
      title: this.lang[this.activeLang].contact_info.dob,
      type: 'datepicker',
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else"
      }
    })

    this.addToRow('report_incident', 6, 'affected_gender_typed', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.other_title,
      type: 'text',
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_pronoun') == 'Other' && this.getFormData('affected_person') == "Someone else"
      },
    })

    this.addToRow('report_incident', 7, 'affected_religion', {
      title: this.lang[this.activeLang].contact_info.religion_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_religion_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && formRequest.apiData.get_religion_list.options[0]
      },
    })

    this.addToRow('report_incident', 8, 'affected_religion_typed', {
      title: this.lang[this.activeLang].contact_info.religion_select.other_title,
      type: 'text',
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && this.getFormData('affected_religion') == 'Other'
      }
    })

    this.addToRow('report_incident', 9, 'affected_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_Ethnicity_list.options,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && formRequest.apiData.get_Ethnicity_list.options[0]
      },
    })

    this.addToRow('report_incident', 10, 'affected_ethnicity_typed', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.other_title,
      type: 'text',
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') == "Someone else" && this.getFormData('affected_ethnicity') == 'Other'
      }
    })

    this.addToRow('report_incident', 11, 'against_type', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.title,
      type: 'select',
      options: formRequest.apiData.get_againsttype_list.options
    })

    this.addToRow('report_incident', 11, 'incident_date', {
      title: this.lang[this.activeLang].report_incident.date,
      type: 'datepicker',
    })

    this.addToRow('report_incident', 12, 'complaint_airport', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.airport,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Airport"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_employer_name', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.employer,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Employer"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_government_agency', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.government,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_governmentactors_list.options,
      shouldShow: () => {
        return this.getFormData('against_type') == "Government Agency"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_educational', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.educational,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Educational Institution"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_healthcare', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.healthcare,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Healthcare Facility"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_prison_select', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.prison_select.title,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_facilitytype_list.options,
      shouldShow: () => {
        return this.getFormData('against_type') == "Prison/Jail/Detention/Mental Health Facility"
      }
    })

    this.addToRow('report_incident', 13, 'complaint_prison_name', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.prison_select.name,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Prison/Jail/Detention/Mental Health Facility"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_mental', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.mental,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Mental Health Facility"
      }
    })

    this.addToRow('report_incident', 12, 'complaint_other', {
      title: this.lang[this.activeLang].report_incident.complaint_against_select.other,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('against_type') == "Other"
      }
    })
  },
  methods: {
    formFieldChanged(fieldID, val) {
      // Storing the values into local storage so data is maintained across page navigations.
      this.formData[fieldID] = val
      localStorage.formData = JSON.stringify(this.formData)
    },
    clearFormData() {
      delete localStorage.formData
    },
    async submitForm() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      this.JSONData = JSON.stringify(this.formData, null, 2)
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) {
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({ block: 'center' })
          break
        }
      } else {
        const status = await formRequest.submitForm(this.JSONData)
        if (status === 200) {
          this.submitted = true
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging) delete localStorage.formData
      }
    },
    addSection(title, sectionData) {
      this.formStructure[title] = sectionData || {}
    },
    addToRow(section, rowID, index, content) {
      content.section = section
      content.index = index
      // Add a content to field to the section if it does not exist already.
      this.formStructure[section].content =
        this.formStructure[section].content || []
      // Create a new row to the section content if it does not exist already.
      this.formStructure[section].content[rowID] =
        this.formStructure[section].content[rowID] || {}
      // Finally add the content to the section, content, row.
      this.formStructure[section].content[rowID][index] = content
    },
    getFormData(index) {
      // Alternative to indexing form data.
      return this.formData[index]
    },
    getInputError(index, input) {
      let showingSection = this.formStructure[input.section].shouldShow

      // If the section is not visible, it is not required to have a value.
      if (showingSection && !showingSection()) return false

      // If the input is not visible, it is not required to have a value.
      let showingInput = input.shouldShow
      if (showingInput && !showingInput()) {
        return false
      }

      // If the input is visible and is not required and is empty we're not gonna perform any valid checks.
      if (!input.required && !this.formData[index]) return false

      // If the input is required and is not inside the formData, then they skipped this input.
      if (!this.formData[index] || this.formData[index] === '') {
        input.hasError = true
        input.requiredError = true
        return true
      }

      // Finally perform valid checks such as email, zip or phone number.
      if (input.isValid) {
        let errorMsg = input.isValid(this.formData[input.index])
        if (errorMsg) {
          input.hasError = true
          input.errorMsg = errorMsg
          return true
        }
      }
    },
    isFormValid() {
      // TODO: Refactor formStructure with a better data structure.
      let isValid = true
      this.errorFields = []
      // eslint-disable-next-line no-unused-vars
      for (let [title, section] of Object.entries(this.formStructure)) {
        for (let inputs of section.content) {
          for (let [inputIndex, input] of Object.entries(inputs)) {
            // Reset any previous errors when performing the form valid check.
            input.hasError = false
            if (this.getInputError(inputIndex, input)) {
              isValid = false
              this.erroredFields.push(input)
            }
          }
        }
      }
      return isValid
    },
    isBaseInfoComplete(){
      if (this.getFormData('filer_country') && this.getFormData('filer_city') && (this.getFormData('filer_state') || this.getFormData('filer_state_typed')) && this.getFormData('incident_city') && (this.getFormData('incident_state') || this.getFormData('incident_state_typed')) && this.getFormData('nature'))
        return true
      else
        return false
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#error-notice {
  width: 100%;
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
  float: left;
  text-decoration: underline;
}

.debug-button {
  background-color: rgb(214, 88, 88);
  padding: 10px;
  margin: 20px 25%;
  cursor: pointer;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.submit-button {
  background-color: #FFFFFF;
  border: 2px solid #c36;
  color: #c36;
  padding: 10px 30px;
  border-radius: 0px;
  margin: 30px 25% 120px 25%;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4rem;
}

.submit-button:hover {
  background-color: #c36;
  color: #FFF;
  transition: background-color 0.5s ease;
}

.submit-button-text {
  pointer-events: none;
}
</style>
