<template>
  <div>
    <div class="content-container">
      <InquiryForm
        v-if="lang"
        class="form-container"
        :active-lang="activeLang"
      />
    </div>
  </div>
</template>

<script>
import InquiryForm from './components/InquiryForm.vue'

export default {
  name: 'App',
  components: {
    InquiryForm
  },
  data() {
    return {
      activeLang: localStorage.activeLang || 'English'
    }
  },
  created() {
    // Validate activeLang value just incase we change it or someone attemps to change it elsewhere.  
    this.activeLang = this.lang[localStorage.activeLang] && localStorage.activeLang || 'English'
    localStorage.activeLang = this.activeLang
  },
  methods: {
    updateLang(val) {
      this.activeLang = val
      localStorage.activeLang = val
    }
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: block;
  border-top: 5px solid #F89828;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  padding:0;
  margin:0;
  overflow: auto;
}

HTML, BODY {
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  margin:0;
  padding:0;
  overflow: hidden;
}

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #e5eaf3 inset;
  box-shadow: 0 0 0px 1000px #e5eaf3 inset;
}

a {
  color: #3c79b9;
  font-weight: 600;
}

.line-spacer {
  background-color: rgb(226, 226, 226);
  height: 1px;
  border: none;
}

.content-container {
  margin: 0 16px 100px 16px;
  max-width: 1200px;
  min-height: 60vh;
}

.heading-block {
  background-color: #423a38;
  color: #FFF;
  background: url('@/assets/Resources.jpg');
  background-size: cover;
  height: 400px;
  background-position: center center;
}

.heading-block h1 {
  margin: 0 16px;
  font-size: 1.4rem;
  text-align: left;
  max-width: 1200px;
}

.footer {
  margin-top: 25px;
  clear: both;
  position: relative;
}

@media screen and (min-width: 700px) {
  .content-container {
    width: 80%;
    margin: 0 auto 100px auto;
  }

  .heading-block h1 {
    width: 80%;
    margin: 0 auto;
  }

  .footer {
    min-height: 200px;
  }
}
@media screen and (min-width: 1400px) {
  .content-container {
    width: 70%;
    margin: 0 auto 100px auto;
  }
  
  .heading-block h1 {
    width: 70%;
    margin: 0 auto;
  }
}
</style>
